import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductsThirdLevel.module.scss";
import { getFixcenterFilteringCategories, getProductById, getProductImage } from "../../services/products.service";
import { useParams } from "react-router-dom";
import { Product } from "../../components/products-page/models";
import { ProductImage } from "../../shared/product-card/models/product-image.model";
import { decodeImageService } from "../../shared/services";
import FixcenterOrderingTable from "../../components/FixcenterOrderingTable/FixcenterOrderingTable";
import FixcenterSupport from "../../components/fixcenter-support/FixcenterSupport";
import { FixcenterProduct } from "../../shared/models/fixcenter-product.model";
import { getAttachmentsByProductId } from "../../shared/services/attachments.service";
import { AttachmentFile } from "../../shared/models/attachment-file.model";
import { determineIfRedirectOrDownload } from "../../shared/services/attachments.service";
import FixcenterNavigation from "../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation";
import { PRODUCT_THIRD_LEVEL_PATHS } from "../../shared/constants/breadcrumb.constants";
import { setBreadcrumbs } from "../../store/reducers";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import { Language } from "../../shared/models";
import { getLanguages } from "../../shared/services/language.service";

export default function ProductsThirdLevel() {
    const documentsRef = useRef(null);
    const orderingRef = useRef(null);
    const relatedProductsRef = useRef(null);
    const supportRef = useRef(null);
    const [product, setProduct] = useState<FixcenterProduct>();
    const [productImage, setProductImage] = useState<string>();
    const defaultImage = require('../../assets/img/Fixcenter-noimage.png')
    const [products, setProducts] = useState<FixcenterProduct[]>([]);
    const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [translatedProductName, setTranslatedProductName] = useState<string>();

    const scrollToRef = (ref: any) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    const { productId, categoryName, categoryId } = useParams();

    const [breadcrumbs, setBreadcrumbs] = useState([...PRODUCT_THIRD_LEVEL_PATHS]);

    useEffect(() => {
        fetchLanguages();
    }, [])

    useEffect(() => {
        const updatedBreadcrumbs = [...PRODUCT_THIRD_LEVEL_PATHS];
        if (product && categoryName && categoryId) {
            updatedBreadcrumbs[2] = { ...updatedBreadcrumbs[2], label: categoryName, link: `/webshop/category/${categoryName}/${categoryId}` };
            updatedBreadcrumbs[3] = { ...updatedBreadcrumbs[3], label: product?.name };
        }
        setBreadcrumbs(updatedBreadcrumbs);
    }, [product]);

    useEffect(() => {
        if (product !== null && product !== undefined && languages.length > 0) {
            const currentLanguageCode = localStorage.getItem("lang");
            const foundLanguage = languages.find(x => x.code === currentLanguageCode);
            console.log(foundLanguage);
            if (foundLanguage) {
                console.log(foundLanguage);
                setTranslatedProductName(product.translation?.translationObjects.find(x => x.languageId === foundLanguage.id)?.translation);
            }
        }
    }, [product, languages])

    useEffect(() => {
        if (productId !== null) {
            fetchProduct();
            fetchProductImage()
            fetchAttachments();
        }
    }, [productId]);

    useBreadcrumbs(breadcrumbs);

    const fetchProduct = (): void => {
        if (productId !== undefined) {
            getProductById(productId)
                .then((response: any) => {
                    if (response) {
                        const product = response.data as FixcenterProduct;
                        setProduct(product);

                    }
                })
                .catch((error: any) => {
                    console.error("Błąd podczas pobierania produktu:", error);
                });
        }
    }

    const fetchProductImage = () => {
        if (productId !== undefined) {
            getProductImage(productId).then((response: any) => {
                const image = response.data as ProductImage[];
                setProductImage(decodeImageService(image[0]?.content, image[0]?.name) as string);
            })
        }
    }

    const fetchAttachments = async () => {
        if (productId !== undefined) {
            getAttachmentsByProductId(productId).then((response: any) => {
                if (response) {
                    const attach = response.data as AttachmentFile[];
                    let attachedFiles: AttachmentFile[] = [];
                    let i = 0;
                    attach.forEach(x => {
                        let extension = x.name.slice(-3);
                        if (extension == 'rfa' || extension == 'pdf') {
                            attachedFiles[i++] = x;
                        }
                    });
                    setAttachments(attachedFiles);
                }
            });
        }
    };

    const fetchLanguages = async () => {
        getLanguages().then((response: any) => {
            if (response) {
                setLanguages(response.data as Language[]);
            }
        })
    }


    const handleSetProducts = (products: FixcenterProduct[]) => {
        setProducts(products);
    };

    const determineValueToDisplay = (product: FixcenterProduct, index: number) => {

        let value = product.featuresWithNames[index].value;

        if (value.textValue) {
            return value.textValue;
        }
        else if (value.numericValue) {
            return value.numericValue;
        }
        else if (value.dateValue) {
            return value.dateValue;
        }
        else if (value.numericRangeFrom && value.numericRangeTo) {
            const minRangeFrom = product.featuresWithNames.reduce((min: number | undefined, feature) => {
                const rangeFrom = feature.value.numericRangeFrom;
                return rangeFrom !== undefined && (min === undefined || rangeFrom < min) ? rangeFrom : min;
            }, undefined as (number | undefined));

            const maxRangeTo = product.featuresWithNames.reduce((max: number | undefined, feature) => {
                const rangeTo = feature.value.numericRangeTo;
                return rangeTo !== undefined && (max === undefined || rangeTo > max) ? rangeTo : max;
            }, undefined as (number | undefined));

            return `${minRangeFrom}:${maxRangeTo}`;
        }
        else if (value.enumValue) {
            return value.enumValue;
        }

        else if (value.enumValues) {
            return value.enumValues;
        }
        else if (value.booleanValue !== undefined) {
            return value.booleanValue ? 'YES' : 'NO';
        }

        return '';

    }



    return (
        <div className={styles.container}>
            <div className={styles.navigation}>
                {product?.name &&
                    <FixcenterNavigation />}
            </div>
            <div className={styles.section_title}>
                <h3>{translatedProductName && translatedProductName}</h3>
            </div>
            <div className={styles.content}>
                <div className={styles.product_image_container}>
                    <img src={productImage !== undefined ? productImage : defaultImage} alt="Product" />
                </div>
                <div className={styles.description_container}>
                    <button>Add to cart</button>
                    <p onClick={() => scrollToRef(documentsRef)}>Documents</p>
                    <p onClick={() => scrollToRef(orderingRef)}>Ordering</p>
                    <p onClick={() => scrollToRef(relatedProductsRef)}>Related products</p>
                    <p onClick={() => scrollToRef(supportRef)}>Support</p>
                    <div className={styles.schema_values}>
                        {products && products.length > 0 &&
                            products[0].featuresWithNames &&
                            products[0].featuresWithNames.length > 0 &&
                            products[0].featuresWithNames.map((f, index) => (
                                <div key={index} className={styles.feature_item}>
                                    <span className={styles.feature_name}>
                                        {f.featureName} : <span className={styles.bold}>{String(determineValueToDisplay(products[0], index))}</span>
                                    </span>
                                </div>
                            ))}
                    </div>


                </div>
            </div>
            <div ref={documentsRef} className={styles.section_title}>
                <h3>DOCUMENTS</h3>
                {
                    attachments && attachments.length > 0 &&
                    attachments.map((a, index) => (
                        <div onClick={() => { determineIfRedirectOrDownload(a) }} key={index} className={styles.pdf_link}>{a.name}</div>
                    ))
                }
            </div>
            <div ref={orderingRef} className={styles.section_title}>
                <h3>ORDERING</h3>
                <div className={styles.section_content}>
                    <FixcenterOrderingTable handleSetProducts={handleSetProducts} />
                </div>

            </div>
            <div ref={relatedProductsRef} className={styles.section_title}>
                <h3>RELATED PRODUCTS</h3>
            </div>
            <div ref={supportRef} className={styles.section_title}>
                <h3>SUPPORT</h3>
                <div className={styles.section_content}>
                    <FixcenterSupport variant={2} />
                </div>
            </div>
        </div>
    );
}
