import React, { useEffect, useState } from 'react';
import styles from "./Header.module.scss";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as authService from '../../auth/services/auth.service';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Language } from '../models';
import ToggleSwitch from '../toggle-switch/ToggleSwitch';
import { getLanguages } from '../services/language.service';
import SearchInput from '../../components/search-input/SearchInput';
import { User } from '../../components/client-panel/components/my-account-info/models/user.model';
import { getCurrentUser } from '../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


const logoIcon = require("../../assets/icons/logotyp.png");
const loginIcon = require("../../assets/icons/login_icon.png");
const registerIcon = require("../../assets/icons/register_icon.png")
const homeIcon = require("../../assets/icons/home.png")
const creditCard = require("../../assets/icons/credit_card.png")
const fileIcon = require("../../assets/icons/file_icon.png")
const cartIcon = require("../../assets/icons/cart_icon.png")

export default function Header(props: any) {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const [toggled, setToggled] = useState(false);
    const [allLanguages, setAllLanguages] = useState<Language[]>([]);
    const location = useLocation();
    const [userDetails, setUserDetails] = useState<User>();

    const logOut = (navigate: NavigateFunction) => authService.logOut(navigate);

    useEffect(() => {
        handleSetLanguages();
        fetchUserDetails();
    }, [])

    const headerItems = [
        { label: "_Invoices", link: "/" },
        { label: "Calculation program", link: "/" },
        { label: "Web shop", link: "/webshop" }
    ];

    const toggleViewMode = () => {
        setToggled(!toggled);
    }

    const fetchUserDetails = () => {
        getCurrentUser().then((response) => {
            if (response.data) {
                setUserDetails(response.data as User);
            }
        })
    }

    const applySelectedClass = (path: string) => {
        const currentPath = location.pathname;
        return path == currentPath ? `${styles.link_div} ${styles.selected_link}` : `${styles.link_div}`;
    }

    const handleSetLanguages = () => {
        getLanguages().then((result) => {
            if (result) setAllLanguages(result?.data as Language[]);
        });

    };

    const checkIfIsLoggedInAndNavigate = (): string => {
        const isLoggedIn: boolean = authService.checkLogInStatus();
        return isLoggedIn ? '/webshop' : '/Home/Product';
    }

    const shouldShowShoppingCart = (): boolean => /webshop|cart/.test(location.pathname.toLowerCase());

    return (
        <div className={styles.main_container}>
            <div className={styles.upper_part}>
                <div className={styles.left_side}>
                    <div className={styles.logo_wrapper}>
                        <img onClick={() => { navigate("/"); navigate(0) }} src={logoIcon} alt="Logo" />
                    </div>
                </div>
                <div className={styles.right_side}>
                    <div className={styles.top_bar_actions_wrapper}>
                        {authService.checkLogInStatus() &&
                            <SearchInput />
                        }
                        <div className={styles.language_select_container}>
                            <select
                                value={localStorage.getItem("lang") || "pl"}
                                onChange={(event) => {
                                    i18n.changeLanguage(event.target.value);
                                    localStorage.setItem("lang", event.target.value);
                                    window.location.pathname = '/';
                                }}
                            >

                                {
                                    allLanguages?.map((item, index) => (
                                        <option key={index} value={item?.code}>
                                            {t("languages." + item?.code)}
                                        </option>
                                    ))

                                }
                            </select>
                        </div>
                        <div className={styles.toggle_switch_container} onClick={toggleViewMode}>
                            <ToggleSwitch toggled={toggled} />
                        </div>
                        {authService.checkLogInStatus() && headerItems.map((item, index) => (
                            <div key={index} className={styles.header_element}>
                                <span onClick={() => navigate(item?.link)}>{item.label}</span>
                            </div>
                        ))}
                        {authService.checkLogInStatus() && userDetails !== null &&
                            <div className={`${styles.header_element} ${styles.column}`}>
                                <span>{userDetails?.firstName + " " + userDetails?.lastName}</span>
                                <span>{userDetails?.email}</span>
                            </div>
                        }

                        {
                            !authService.checkLogInStatus() ?
                                <>
                                    <div className={styles.login_container}>
                                        <img src={loginIcon} />
                                        <span onClick={() => navigate('/login')}>{t("header.login")}</span>
                                    </div>
                                    <div className={styles.register_container}>
                                        <img src={registerIcon} />
                                        <span onClick={() => navigate('/register')}>{t("header.register")}</span>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={styles.logout_container}>
                                        <span onClick={() => logOut(navigate)}>{t("header.logout")}</span>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.bottom_part}>
                <div className={`${styles.links_container}`}>
                    <Link to="/" className={applySelectedClass('/')}>
                        {t("header.home")}
                    </Link>
                    <Link to={checkIfIsLoggedInAndNavigate()} className={applySelectedClass('/products')}>
                        {t("header.products")}
                    </Link>

                    <Link to="/engineering" className={applySelectedClass('/engineering')}>
                        {t("header.ENGINEERING")}
                    </Link>
                    <Link to="/services" className={applySelectedClass('/services')}>
                        {t("header.SERVICES")}
                    </Link>
                    <Link to="/company" className={applySelectedClass('/company')}>
                        {t("header.COMPANY")}
                    </Link>
                    <Link to="/visit-us" className={applySelectedClass('/visit-us')}>
                        {t("header.VISIT US")}
                    </Link>
                    <Link to="/career" className={applySelectedClass('/career')}>
                        {t("header.CAREER")}
                    </Link>
                    <Link to="/contact-us" className={applySelectedClass('/contact-us')}>
                        {t("header.CONTACT US")}
                    </Link>
                    {authService.checkLogInStatus() && shouldShowShoppingCart() &&
                        <div className={`${applySelectedClass('/career')} ${styles.dropdown_container}`}>
                            <span>
                                {t("header.shoppingCart")} <img src={cartIcon} />▾
                            </span>
                            <div className={styles.dropdown_menu}>
                                <Link to="/Cart">{t("header.shoppingCart")} <img src={cartIcon} /></Link>
                                <Link to="/Cart/Order/List">{t("header.myOrders")}<img src={creditCard} /></Link>
                                <Link to="/Cart/BackOrder/List">{t("header.myBackOrders")}<img src={creditCard} /></Link>
                                <Link to="/Cart/Invoices/List">{t("header.myInvoices")} <img src={fileIcon} /></Link>
                                <Link to="/Cart/Offers/List">{t("header.myOffers")}</Link>
                                <Link to="/Cart/BuildingSites/List">{t("header.buildingSites")}<img src={homeIcon} /></Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )

}